<template>
  <v-card class="mx-auto">
    <v-tabs
        bg-color="primary"
        v-model="tab"
        align-tabs="center"
        fixed-tabs
        show-arrows
        center-active>
      <v-tab max-width="300px" text="Filters" value="filter"></v-tab>
      <v-tab text="Describe"></v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="filter">
          <AutoCompleteField
            dense
            label="Role"
            item-title="Name"
            item-value="Name"
            :items="details"
            v-model="searchCriteria.jobRole"
            outlined
          />
          <AutoCompleteField
            dense
            label="Skills"
            v-model="searchCriteria.skills"
            :items="skills"
            item-title="Text"
            item-value="Text"
            multiple
            chips
            closable-chips
            outlined
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item?.raw?.Text"
                :value="item?.raw?.id"
              ></v-list-item>
            </template>
          </AutoCompleteField>
          <AutoCompleteField
            dense
            label="Min. Total Experience"
            :items="experienceYearList"
            v-model="searchCriteria.totalExperience"
            outlined
          />
          <AutoCompleteField
            dense
            label="Location"
            item-title="text"
            item-value="text"
            :items="locations"
            multiple
            chips
            closable-chips
            outlined
            v-model="searchCriteria.locations"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item?.raw?.text"
                :value="item?.raw?.text"
              ></v-list-item>
            </template>
          </AutoCompleteField>
        </v-tabs-window-item>
        <v-tabs-window-item>
          <v-textarea
            bg-color="primary"
            append-inner-icon="mdi-magnify"
            density="comfortable"
            variant="outlined"
            hide-details="auto"
            placeholder="Search"
            hint="Describe your job role"
            @click:append-inner="searchByDescription"
            v-model="jobDescription"
            auto-grow
          ></v-textarea>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import { PropType, defineComponent } from "vue";
import { Profile } from "shared-components/src/definitions/config";
import { SearchTeammemberRequestModel } from "shared-components/src/services/openApi/api";
export default defineComponent({
  props: {
    positionJD: {
      type: String,
      required: false,
    },
    criteria: {
      type: Object as PropType<SearchTeammemberRequestModel>,
      required: true,
    },
    experienceYearList: {
      type: Array as () => string[],
      default: Profile.Members.SkillExperienceList
    },
    skills: {
      type: Array as () => CoreSkill[],
      required: true,
    },
    details: {
      type: Array as () => Details[],
      required: true,
    },
  },
  async mounted() {
    this.ageRangeMin = this.ageRange[0];
    this.ageRangeMax = this.ageRange[1];
    this.locations = this.criteria.locations == null ? this.locations : this.criteria.locations.map(str => ({ text: str }));
    this.criteria.locations = [];
  },
  components: {},
  data() {
    return {
      jobDescription: this.positionJD,
      tab: 'filters',
      locations: [
        {text: "Australia"},{text: "Dubai"},{text: "Hong Kong"} ,{text: "India"} ,{text: "Iran"},{text: "Malaysia"},{text: "Philippines"},{text: "Singapore"} ,{text: "Taiwan"}],
      searchCriteria: this.criteria,
      ageRange: this.criteria.age ?? [10, 70],
      ageRangeMin: 10,
      ageRangeMax: 70,
    };
  },
  methods: {
    searchByDescription() {
      this.searchCriteria.description = this.jobDescription?.toString();
      this.$emit("doSearch", this.searchCriteria);
    },
    AgeChanged() {
      if (
        !this.searchCriteria.age ||
        (this.ageRange as any)[0] != (this.searchCriteria.age as any)[0] ||
        (this.ageRange as any)[1] != (this.searchCriteria.age as any)[1]
      ){
        this.searchCriteria.age = this.ageRange;
      }
        
    },
  },
  watch: {
    criteria: {
      handler(newVal) {
        this.searchCriteria = newVal;
        if(!this.searchCriteria.age){
          this.ageRange = [10, 70]
        }
        
      },
      deep: true,
    },
    searchCriteria: {
      handler(val) {
        this.$emit("doSearch", this.searchCriteria);
      },
      deep: true,
    },
  },
});
</script>
<style scoped lang="scss">
.main-filter-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;

  .filter-header {
    color: #fff;
  }

  .filter-body {
    background-color: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
