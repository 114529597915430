import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import dashboard from "../views/Dashboard/Dashboard.vue";
import Logout from "../components/Auth/Logout.vue";
import login from "../components/Auth/Login.vue";
import checkMagicLink from "../components/Auth/CheckMagicLink.vue";
import mainLayout from "../views/layouts/MainLayout.vue";
import ProjectLeadReport from "../views/ProjectLeadReport/ProjectLeadReport.vue";
import PortfolioManagerReport from "../views/PortfolioManagerReport/PortfolioManagerReport.vue";
import Search from "../views/Search/Search.vue";
import MyProjects from "../views/MyProjects/MyProjects.vue";
import NotificationList from "../components/User/NotificationList.vue";
import MyTraining from "../views/Training/RoadMap/MyTraining.vue";
import CourseList from "../views/Training/Course/CourseList.vue";
import CourseDetail from "../views/Training/Course/CourseDetail.vue";
import AddOrEditCourse from "../views/Training/Course/AddOrEditCourse.vue";
import AssessmentList from "../views/Training/Assessment/AssessmentList.vue";
import AddOrEditAssessment from "../views/Training/Assessment/AddOrEditAssessment.vue";
import AddOrEditRoadMap from "../views/Training/RoadMap/AddOrEditRoadMap.vue";
import AssessmentDetail from "../views/Training/Assessment/AssessmentDetail.vue";
import SearchWizard from "../views/Search/SearchWizard.vue";
import LoginLayout from "../components/Auth/Layouts/LoginLayout.vue";
import store from "@/store";
import VirtualDesktop from "../views/VirtualDesktop/VirtualDesktop.vue";
import Project from "../views/Project/Project.vue";
import ProjectLead from "../views/ProjectLead/ProjectLead.vue";
import PortfolioManager from "../views/PortfolioManager/PortfolioManager.vue";

const assessmentData = [
  {
    Description: "Test DEC",
    Id: "68346c35-bdef-4171-b5b6-c47be6fc8793",
    Objective: "Test Ob",
    PassScore: 12,
    Questions: [
      {
        Id: "Question1",
        Duration: 1,
        Editors: [
          {
            Id: "710494b0-220d-4a1c-a1e5-16b5c730862a",
            Value: "Value Editor 1",
          },
          {
            Id: "bf10eb00-2be6-4f70-a14f-0c4c6da2fd22",
            Value: "Value Editor 2",
          },
        ],
        Evaluations: [
          {
            Criteria: "Test 1 Criteria 11",
            Point: 1,
          },
          {
            Criteria: "Test 1 Criteria 22",
            Point: 2,
          },
          {
            Criteria: "Test 1 Criteria 33",
            Point: 3,
          },
        ],
        Level: "Easy",
        Order: 0,
        Question: "Test Question 1",
        Technologies: [
          { Id: "r4oogptcOyQ3mTEKXeBq", Value: "Technology 1" },
          { Id: "UixH0xcCzQMRu0SDE64C", Value: "Technology 2" },
        ],
      },
      {
        Id: "Question12",
        Duration: 2,
        Editors: [
          { Id: "371a4a27-4ed6-429b-9e1b-9db980b92ad1", Value: "Editor 21" },
          { Id: "710494b0-220d-4a1c-a1e5-16b5c730862a", Value: "Editor 22" },
        ],
        Evaluations: [
          {
            Criteria: "Test 2 Criteria 11",
            Point: 1,
          },
          {
            Criteria: "Test 2 Criteria 22",
            Point: 2,
          },
          {
            Criteria: "Test 2 Criteria 33",
            Point: 3,
          },
        ],
        Level: "Hard",
        Order: 1,
        Question: "Test Question 2",
        Technologies: [
          { Id: "EucWN0Q7Y6CzF9kNmGwm" },
          { Id: "Ej8I9s3ciDWjQIXCCVDx" },
        ],
      },
    ],
    Tags: ["TypeScript", "JavaScript"],
    Title: "Test Title",
  },
  {
    Description: "Another Test Description",
    Id: "87a4f2bd-9c7e-4a6a-8ee7-5fb1a9d3e8c1",
    Objective: "Another Test Objective",
    PassScore: 15,
    Questions: [
      {
        Id: "Question123",
        Duration: 3,
        Editors: [
          {
            Id: "28a3bc56-7547-4b29-a1cb-6e1f29d15a92",
            Value: "New Editor 1",
          },
          {
            Id: "5f86247c-5ec1-4427-918c-df0d43c46144",
            Value: "New Editor 2",
          },
        ],
        Evaluations: [
          {
            Criteria: "New Test Criteria 1",
            Point: 2,
          },
          {
            Criteria: "New Test Criteria 2",
            Point: 3,
          },
          {
            Criteria: "New Test Criteria 3",
            Point: 4,
          },
        ],
        Level: "Medium",
        Order: 0,
        Question: "Another Test Question 1",
        Technologies: [
          { Id: "PmKRVgA53jN2yJi4yfDH", Value: "New Technology 1" },
          { Id: "Jz3D5iXnC6E4mY0HsQlP", Value: "New Technology 2" },
        ],
      },
      {
        Id: "Question1234",
        Duration: 2,
        Editors: [
          {
            Id: "d7e7f07c-b9d8-4e22-937e-792ff23d6d41",
            Value: "Editor X",
          },
          {
            Id: "a2f864a8-c260-4b6d-af97-2d2d6f5bb4e4",
            Value: "Editor Y",
          },
        ],
        Evaluations: [
          {
            Criteria: "New Test Criteria A",
            Point: 1,
          },
          {
            Criteria: "New Test Criteria B",
            Point: 2,
          },
          {
            Criteria: "New Test Criteria C",
            Point: 3,
          },
        ],
        Level: "Hard",
        Order: 1,
        Question: "Another Test Question 2",
        Technologies: [
          { Id: "n8bUawZ5tpqCfW2pDsgo", Value: "Tech X" },
          { Id: "RlRDiHK3eYi2jv9gN1ps", Value: "Tech Y" },
        ],
      },
    ],
    Tags: ["Java", "Python"],
    Title: "Another Test Title",
  },
  {
    Description: "Third Test Description",
    Id: "cfe8d6ef-3c97-48a1-9b37-7b17dcb81e13",
    Objective: "Third Test Objective",
    PassScore: 18,
    Questions: [
      {
        Id: "Question12345",
        Duration: 4,
        Editors: [
          {
            Id: "bc134fb7-5bf8-4ef6-b6a1-1ff3c6e62f54",
            Value: "Editor Alpha",
          },
          {
            Id: "af56bde2-ec44-4d02-8e11-c7e9f37e3b49",
            Value: "Editor Beta",
          },
        ],
        Evaluations: [
          {
            Criteria: "Test Criteria X",
            Point: 3,
          },
          {
            Criteria: "Test Criteria Y",
            Point: 4,
          },
          {
            Criteria: "Test Criteria Z",
            Point: 5,
          },
        ],
        Level: "Easy",
        Order: 0,
        Question: "Third Test Question 1",
        Technologies: [
          { Id: "VxWp9GKmX37Aqj1cR4Zy", Value: "Tech Alpha" },
          { Id: "fEhDwXsgYzW5KuRQj7lQ", Value: "Tech Beta" },
        ],
      },
      {
        Id: "Question123456",
        Duration: 2,
        Editors: [
          {
            Id: "89b9d1f8-8c6d-4d6b-8e4d-53373c57f3a1",
            Value: "Editor Omega",
          },
          {
            Id: "93fba69a-2be5-44cd-847b-56b0b0c282e0",
            Value: "Editor Delta",
          },
        ],
        Evaluations: [
          {
            Criteria: "Test Criteria M",
            Point: 2,
          },
          {
            Criteria: "Test Criteria N",
            Point: 3,
          },
          {
            Criteria: "Test Criteria O",
            Point: 4,
          },
        ],
        Level: "Medium",
        Order: 1,
        Question: "Third Test Question 2",
        Technologies: [
          { Id: "qZfE6YpWcRd8hUgVxMjT", Value: "Tech Gamma" },
          { Id: "XwvC5sjHtEjWnNcLp1Rm", Value: "Tech Delta" },
        ],
      },
    ],
    Tags: ["C#", "SQL"],
    Title: "Third Test Title",
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/client",
  },
  {
    path: "/clientProfile",
    redirect: "/client/profile",
  },
  {
    path: "/login",
    redirect: "/authentication/login",
  },
  {
    path: "/checkMagicLink/:token",
    redirect: "/authentication/checkMagicLink/:token",
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/authentication",
    name: "authentication",
    component: LoginLayout,
    children: [
      {
        path: "",
        redirect: "login",
      },
      {
        path: "login",
        name: "login",
        meta: {
          title: "Login",
          description:
            "Please use this page to email yourself a magic link that will log you in",
        },
        component: login,
      },
      {
        path: "checkMagicLink/:token",
        name: "CheckMagicLink",
        props: true,
        component: checkMagicLink,
        meta: {
          title: "Login",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    alias: "/login",
    component: login,
  },
  {
    path: "/client",
    name: "clientProfile",
    component: mainLayout,
    children: [
      {
        path: "",
        name: "preDashboard",
        redirect: "/client/dashboard",
        meta: {
          roles: ["portfoliomanager", "projectlead", "client"],
        },
      },
      {
        path: "",
        name: "preDashboardTdm",
        redirect: "/client/training/roadMap",
        meta: {
          roles: ["tdm"],
        },
      },
      {
        path: "dashboard",
        name: "clientDashboard",
        meta: {
          title: "Dashboard",
          icon: "mdi-account",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: dashboard,
      },
      {
        path: "projectLeadreport",
        name: "projectLeadReports",
        meta: {
          title: "Project Lead Reports",
          icon: "mdi-chart-arc",
          roles: ["projectlead"],
        },
        component: ProjectLeadReport,
      },
      {
        path: "portFolioManagerReport",
        name: "portFolioManagerReports",
        meta: {
          title: "Portfolio Manager Reports",
          icon: "mdi-file-tree",
          roles: ["portfoliomanager"],
        },
        component: PortfolioManagerReport,
      },
      {
        path: "myProjects",
        name: "my-projects",
        meta: {
          title: "My Projects",
          icon: "mdi-file-multiple",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: MyProjects,
      },
      {
        path: "training/roadMap",
        name: "roadmap",
        meta: {
          title: "Training RoadMaps",
          icon: "mdi-account-school",
        },
        component: MyTraining,
      },
      {
        path: "training/roadMap/new",
        name: "addRoadmap",
        meta: {
          title: "Add a new RoadMap",
          icon: "mdi-account-school",
        },
        component: AddOrEditRoadMap,
      },
      {
        path: "training/roadMap/edit/:roadMapId",
        name: "editRoadmap",
        component: AddOrEditRoadMap,
        meta: {
          title: "Edit RoadMap",
          icon: "mdi-account-school",
        },
        props: true,
      },
      {
        path: "training/courses",
        name: "courses",
        meta: {
          title: "courses",
          icon: "mdi-school",
        },
        component: CourseList,
        props: {
          backButtonVisibility: false,
          addToCoursesButtonVisibility: false,
          assessmentBtnVisiblity: false,
          courseBtnVisiblity: true,
          showAsPage: true,
          topValue: "69px",
          heightValue: "545px",
          paddingValue: "35px",
        },
      },
      {
        path: "training/courses/view/:courseId",
        name: "course-detail",
        component: CourseDetail,
        props: true,
      },
      {
        path: "training/courses/new",
        name: "add-course",
        component: AddOrEditCourse,
        props: true,
      },
      {
        path: "training/courses/edit/:courseId",
        name: "edit-course",
        component: AddOrEditCourse,
        props: true,
      },
      {
        path: "training/assessment",
        name: "assessment-list",
        component: AssessmentList,
        meta: {
          title: "Assessments",
          icon: "mdi-book-clock",
        },
        props: {
          showAsPage: true,
          topValue: "69px",
          heightValue: "545px",
          paddingValue: "35px",
          backButtonVisibility: false,
          assessmentBtnVisiblity: true,
          courseBtnVisiblity: false,
          assessmentData,
        },
      },
      {
        path: "training/assessment/new",
        name: "add-assessment",
        component: AddOrEditAssessment,
        props: {
          assessmentData,
        },
      },
      {
        path: "training/assessment/edit/:assessmentId",
        name: "edit-assessment",
        component: AddOrEditAssessment,
        props: true,
      },
      {
        path: "training/assessment/view/:assessmentId",
        name: "assessment-detail",
        component: AssessmentDetail,
        props: true,
      },
      {
        path: "search",
        name: "search",
        meta: {
          title: "Search",
          icon: "mdi-search",
          roles: ["portfoliomanager"],
        },
        component: Search,
        props: true,
      },
      {
        path: "search/wizard",
        name: "searchWizard",
        meta: {
          title: "Search",
          icon: "mdi-search",
          roles: ["portfoliomanager"],
        },
        component: SearchWizard,
      },
      {
        path: "virtualDesktop",
        name: "virtualDesktop",
        meta: {
          title: "Smart Workbench",
          icon: "mdi-lock-smart",
          roles: ["portfoliomanager", "client"],
        },
        component: VirtualDesktop,
      },
      {
        path: "project",
        name: "project",
        meta: {
          title: "Project",
          icon: "mdi-file-tree",
          roles: ["portfoliomanager", "client"],
        },
        component: Project,
        props: true,
      },
      {
        path: "projectLead",
        name: "projectLead",
        meta: {
          title: "Project Lead",
          icon: "mdi-plus",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: ProjectLead,
      },
      {
        path: "portfolioManager",
        name: "portfolioManager",
        meta: {
          title: "Portfolio Manager",
          icon: "mdi-plus",
          roles: ["portfoliomanager", "projectlead", "client"],
        },
        component: PortfolioManager,
      },
      {
        path: "notifications",
        name: "Notification List",
        meta: {
          title: "Notification List",
          icon: "mdi-bell",
        },
        component: NotificationList,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("showLoading");
  if (to) {
    if (to.meta && to.meta.roles) {
      var pageRoles = to.meta.roles as string[];
      var userRoles = store.state.clientInfo.roles;
      const found = userRoles.some((r) => pageRoles.indexOf(r) >= 0);
      if (found) {
        next();
      } else {
        if (!store.getters.userIsTDM) {
          next({ name: "preDashboard" });
        } else {
          next({ name: "preDashboardTdm" });
        }
      }
    }
    next();
  }
});
router.afterEach(() => {
  store.dispatch("hiddenLoading");
});

export default router;
