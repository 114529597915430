import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mx-auto" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        "bg-color": "primary",
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "align-tabs": "center",
        "fixed-tabs": "",
        "show-arrows": "",
        "center-active": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, {
            "max-width": "300px",
            text: "Filters",
            value: "filter"
          }),
          _createVNode(_component_v_tab, { text: "Describe" })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_tabs_window, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tabs_window_item, { value: "filter" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    dense: "",
                    label: "Role",
                    "item-title": "Name",
                    "item-value": "Name",
                    items: _ctx.details,
                    modelValue: _ctx.searchCriteria.jobRole,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchCriteria.jobRole) = $event)),
                    outlined: ""
                  }, null, 8, ["items", "modelValue"]),
                  _createVNode(_component_AutoCompleteField, {
                    dense: "",
                    label: "Skills",
                    modelValue: _ctx.searchCriteria.skills,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchCriteria.skills) = $event)),
                    items: _ctx.skills,
                    "item-title": "Text",
                    "item-value": "Text",
                    multiple: "",
                    chips: "",
                    "closable-chips": "",
                    outlined: ""
                  }, {
                    item: _withCtx(({ props, item }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: item?.raw?.Text,
                        value: item?.raw?.id
                      }), null, 16, ["title", "value"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "items"]),
                  _createVNode(_component_AutoCompleteField, {
                    dense: "",
                    label: "Min. Total Experience",
                    items: _ctx.experienceYearList,
                    modelValue: _ctx.searchCriteria.totalExperience,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchCriteria.totalExperience) = $event)),
                    outlined: ""
                  }, null, 8, ["items", "modelValue"]),
                  _createVNode(_component_AutoCompleteField, {
                    dense: "",
                    label: "Location",
                    "item-title": "text",
                    "item-value": "text",
                    items: _ctx.locations,
                    multiple: "",
                    chips: "",
                    "closable-chips": "",
                    outlined: "",
                    modelValue: _ctx.searchCriteria.locations,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchCriteria.locations) = $event))
                  }, {
                    item: _withCtx(({ props, item }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: item?.raw?.text,
                        value: item?.raw?.text
                      }), null, 16, ["title", "value"])
                    ]),
                    _: 1
                  }, 8, ["items", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_tabs_window_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, {
                    "bg-color": "primary",
                    "append-inner-icon": "mdi-magnify",
                    density: "comfortable",
                    variant: "outlined",
                    "hide-details": "auto",
                    placeholder: "Search",
                    hint: "Describe your job role",
                    "onClick:appendInner": _ctx.searchByDescription,
                    modelValue: _ctx.jobDescription,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.jobDescription) = $event)),
                    "auto-grow": ""
                  }, null, 8, ["onClick:appendInner", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}